<template>
  <van-row class="page-wrapper" justify="center">
    <van-col class="itserve-center" span="24">
      <itserve-route
          :buttonName="$t('buttons.payNow')"
          route-name="Pay"
          type="wide"
          class="mt-15"
      ></itserve-route>
    </van-col>
    <van-col class="itserve-center mb-25" span="24">
      <div class="main-page-account place-content-center">
        <div @click="putIndications">
          <itserve-route
              :buttonName="$t('buttons.putIndications')"
              class="nav-btn mt-15-simple"
              :icon="true"
              icon-color="#3C2C93"
              btn-style="box"
          >
            <send-indications-icon />
          </itserve-route>
        </div>
        <itserve-route
            route-name="Pay"
            :params="$route.params"
            :buttonName="$t('buttons.pay')"
            type="wide"
            class="nav-btn mt-15-simple fr"
            :icon="true"
            icon-color="#1AAD62"
            btn-style="box"
        >
          <pay-icon />
        </itserve-route>
        <itserve-route
            route-name="SelectMeterage"
            :buttonName="$t('buttons.energyCalculator')"
            type="wide"
            class="nav-btn mt-15-simple"
            :icon="true"
            icon-color="#D08CFA"
            btn-style="box"
        >
          <calculator-icon size="85%" />
        </itserve-route>
        <div @click="putShutdowns">
          <itserve-route
              :buttonName="$t('buttons.shutdowns')"
              type="wide"
              class="nav-btn mt-15-simple fr"
              :icon="true"
              icon-color="#FF8573"
              btn-style="box"
          >
            <shutdown-icon />
          </itserve-route>
        </div>
        <div @click="socialShare">
        <itserve-route
            :buttonName="$t('buttons.socialShare')"
            :params="$route.params"
            type="wide"
            class="nav-btn mt-15-simple"
            :icon="true"
            icon-color="#0fb1e5"
            btn-style="box"
        >
          <share-icon size="85%" />
        </itserve-route>
        </div>

        <itserve-route
            route-name="WriteTicket"
            :buttonName="$t('buttons.contactUs')"
            :params="$route.params"
            type="wide"
            class="nav-btn mt-15-simple fr"
            :icon="true"
            icon-color="#FCC204"
            btn-style="box"
        >
          <contactus-icon />
        </itserve-route>
      </div>
    </van-col>
  </van-row>
</template>
<script>
import ItserveRoute from "@/components/content/ItserveRoute";
import PayIcon from "@/components/icons/pay-icon-box";
import ShutdownIcon from "@/components/icons/shutdown-icon-box";
import ContactusIcon from "@/components/icons/contactus-icon-box";
import SendIndicationsIcon from "@/components/icons/send-indications-icon-box";
//import ElectricIcon from "@/components/icons/electric-icon-box";
import CalculatorIcon from "@/components/icons/calculator-icon";
import ShareIcon from "vue-material-design-icons/Share";
export default {
  name: "Home",
  created() {
    // this.getAccounts();
  },
  components: {
    SendIndicationsIcon,
    CalculatorIcon,
    ShareIcon,
    ContactusIcon,
    ShutdownIcon,
    PayIcon,
    ItserveRoute,
  },
  computed: {
  },
  methods: {
    putIndications() {
      this.nativeSendEvent("url", process.env.VUE_APP_INDICATORS);
    },
    putShutdowns() {
      this.nativeSendEvent("url", process.env.VUE_APP_SHUTDOWN)
    },
    socialShare() {
      this.nativeSendEvent("socialShare", {
        text: this.$t("settings.title") + " " + process.env.VUE_APP_SOCIAL_SHARE,
        subject: this.$t("settings.subject"),
      });
    }

  },
};
</script>
<style scoped>
@import "../../node_modules/vant/lib/notice-bar/index.css";
@import "../../node_modules/vant/lib/list/index.css";
.home-accounts-list {
  margin-bottom: 15px;
}
.home-page {
  background-color: #fff;
  padding-bottom: 15px;
  flex-grow: 1;
}
.main-page-account {
  padding-bottom: 45px;
}
</style>
<style>
.home-accounts-list .home-account {
  border-radius: 10px;
  max-width: 357px;
  -webkit-box-shadow: 0px 2px 3px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 2px 3px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 2px 3px 2px rgba(34, 60, 80, 0.2);
}
</style>
